import { useCallback, useEffect, useRef, useState } from "react";
import { useSizeCallback } from "./useSizeCallback";

export function useCanvasDimension(mediaStream, videoRef, isRecieveSharing) {
  const [dimension, setDimension] = useState({ width: 0, height: 0 });

  const debounceRef = useRef(setDimension);

  const onCanvasResize = useCallback(
    ({ width, height }) => {
      if (videoRef) {
        // eslint-disable-next-line no-useless-call
        debounceRef.current({ width, height });
      }
    },
    [videoRef],
  );

  useSizeCallback(videoRef.current, onCanvasResize);

  // solo ejecutar esto la primera vez
  useEffect(() => {
    if (videoRef.current) {
      const { width, height } = videoRef.current.getBoundingClientRect();
      setDimension({ width, height });
    }
  }, []);

  useEffect(() => {
    const { width, height } = dimension;
    // si es una etiqueta de html <video>, intentamos
    // manipular el ancho y el alto directamente.
    // si es una etiqueta de html <canvas>, usamos
    // la función de la API de Zoom.
    try {
      if (videoRef.current) {
        videoRef.current.width = width;
        videoRef.current.height = height;
      }
    } catch (e) {
      mediaStream?.updateVideoCanvasDimension(videoRef.current, width, height);
    }
  }, [mediaStream, dimension, videoRef, isRecieveSharing]);

  return dimension;
}
