import React from "react";
import Routing from "./routes/Routing";
import { persistor, store } from "./redux/store/store";
import { Provider } from "react-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";
import colors from "./config/colors";
import "./index.css";
const themeMuiTheme = createTheme(colors);

const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiThemeProvider theme={themeMuiTheme}>
            <Routing />
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
