import { ScreenShare, StopScreenShare } from "@mui/icons-material";
import { ShareStatus } from "@zoom/videosdk";
import React, { useCallback, useEffect } from "react";
import CallButton from "../../../../commons/callButton/CallButton";

const SwitchToScreenSharingButton = ({
  room,
  selfShareCanvas,
  deberiaEstarEnSalaEspera,
}) => {
  let stream = room.getMediaStream();

  const isShareScreen = stream?.getShareStatus() === ShareStatus.Sharing;

  const onScreenShareClick = useCallback(async () => {
    if (stream?.getShareStatus() === ShareStatus.End && selfShareCanvas) {
      await stream?.startShareScreen(selfShareCanvas, {
        requestReadReceipt: true,
      });
    } else if (
      stream?.getShareStatus() === ShareStatus.Sharing &&
      selfShareCanvas
    ) {
      stream?.stopShareScreen();
    }
  }, [stream, selfShareCanvas]);

  // si el paciente estaba compartiendo pantalla, y el profesional
  // se va de la llamada, que el paciente deje de compartir pantalla
  // ya que el botón quedara deshabilitado
  useEffect(() => {
    if (
      stream?.getShareStatus() === ShareStatus.Sharing &&
      deberiaEstarEnSalaEspera
    ) {
      stream?.stopShareScreen();
    }
  }, [stream, deberiaEstarEnSalaEspera]);

  return (
    <CallButton
      src={isShareScreen ? <ScreenShare /> : <StopScreenShare />}
      action={onScreenShareClick}
      disabled={deberiaEstarEnSalaEspera}
    />
  );
};

export default SwitchToScreenSharingButton;
