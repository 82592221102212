import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import { ShareStatus } from "@zoom/videosdk";
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { RoomContext } from "../context/roomContext";

const useStyles = makeStyles((theme) => ({
  screenShareControlBar: {
    color: "#fff",
    padding: "10px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    borderRadius: "14px",
    backgroundColor: "rgba(100, 100, 100, 0.6)",
    top: "2vh",
    position: "fixed",
    width: "20%",
    left: "40%",
    zIndex: 20,
    [theme.breakpoints.down("sm")]: {
      left: "10vw",
      top: "75%",
      width: "80% !important",
    },
    "& .share-bar-canvas": {
      width: "95px",
      height: "55px",
      borderRadius: "5px",
      border: "1px solid #23d959",
    },
    "& button": {
      marginLeft: 10,
      backgroundColor: "rgba(42, 93, 247, 0.75)",
    },
  },
  shareBarHide: {
    display: "none",
  },
}));

const ShareBar = forwardRef((props, ref) => {
  const zmClient = useContext(RoomContext);
  const classes = useStyles();
  const mediaStream = zmClient.getMediaStream();
  const [status, setStatus] = useState(
    mediaStream?.getShareStatus() ?? undefined,
  );

  const onShareStatusChange = useCallback(() => {
    if (status !== mediaStream?.getShareStatus()) {
      setStatus(mediaStream?.getShareStatus());
    }
  }, [status, mediaStream]);

  useEffect(() => {
    zmClient.on("user-updated", onShareStatusChange);
    return () => {
      zmClient.off("user-updated", onShareStatusChange);
    };
  }, [zmClient, onShareStatusChange]);

  return (
    <div
      className={`${
        status === ShareStatus.End ? `${classes.shareBarHide}` : ""
      }`}>
      <div className={classes.screenShareControlBar}>
        {mediaStream?.isStartShareScreenWithVideoElement() ? (
          <video
            id={"ZOOM_WEB_SDK_SHARER_CANVAS"}
            className="share-bar-canvas"
            ref={ref}
          />
        ) : (
          <canvas
            id={"ZOOM_WEB_SDK_SHARER_CANVAS"}
            className="share-bar-canvas"
            ref={ref}
          />
        )}
        <Button
          variant="contained"
          onClick={() => mediaStream.stopShareScreen()}>
          Dejar de compartir
        </Button>
      </div>
    </div>
  );
});

export default ShareBar;
