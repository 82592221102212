import { makeStyles } from "@material-ui/core";
import { esNavegadorMobile } from "../../../../utils/layout";
import CameraButton from "./buttons/CameraButton";
import LeaveRoomButton from "./buttons/LeaveRoomButton";
import MicButton from "./buttons/MicButton";
import SwitchToScreenSharingButton from "./buttons/SwitchToScreenSharingButton";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 5,
    zIndex: 999,
  },
});

const MediaControlsContainer = ({
  room,
  selfShareCanvas,
  deberiaEstarEnSalaEspera,
}) => {
  const classes = useStyles();

  // Por especificación, el botón de compartir pantalla no se debería mostrar
  // para navegadores mobile. Los celulares no permiten (a nivel
  // sistema operativo) compartir pantalla.

  // No utilizamos mediaQueries porque no queremos decidir en base al
  // tamaño del viewport. Por ejemplo, el profesional desde MLNet se conecta
  // con un iframe pequeño (<400px). Si utilizaramos mediaQueries, no se
  // mostraría el botón de compartir pantalla al profesional,
  // aunque sí debería aparecerle.
  const esMobile = esNavegadorMobile();

  return (
    <div className={classes.container}>
      <MicButton room={room} />
      <CameraButton room={room} />
      {!esMobile && (
        <SwitchToScreenSharingButton
          room={room}
          selfShareCanvas={selfShareCanvas}
          deberiaEstarEnSalaEspera={deberiaEstarEnSalaEspera}
        />
      )}
      <LeaveRoomButton room={room} />
    </div>
  );
};

export default MediaControlsContainer;
