import axios from "axios";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Error from "../components/commons/errors/Error";
import NotFound from "../components/commons/errors/NotFound";
import Home from "../components/home/Home.js";
import RoomContainer from "../components/room/RoomContainer";

const Routing = () => {
  axios.defaults.baseURL = CONFIG.baseURL;
  axios.defaults.headers.common["X-Api-Key"] = CONFIG.apiKey;

  return (
    <Router>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<RoomContainer />} path="/room" />
        <Route element={<Error />} path="/error" />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
