import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//TODO: cambiar Provider a APP

ReactDOM.render(
      <App />,
  document.getElementById("root")
);

serviceWorker.unregister();
