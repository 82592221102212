import { useContext, useEffect, useRef } from "react";
import { RoomContext } from "../context/roomContext";

function getStyleAttributeNumericalValue(attr) {
  const v = /(\d+)/.exec(attr)?.[1];
  return v ? Number(v) : 0;
}

function SelfViewContainer(props) {
  const room = useContext(RoomContext);

  const { debeUsarEtiquetaVideo, selfVideoLayout, ...restProps } = props;
  const { style } = restProps;

  const canvasRef = useRef(null);
  const mediaStream = room?.getMediaStream();

  useEffect(() => {
    if (!debeUsarEtiquetaVideo && canvasRef.current && style) {
      const width = getStyleAttributeNumericalValue(style.width);
      const height = getStyleAttributeNumericalValue(style.height);
      try {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      } catch (e) {
        mediaStream?.updateVideoCanvasDimension(
          canvasRef.current,
          width,
          height,
        );
      }
    }
  }, [debeUsarEtiquetaVideo, style, mediaStream]);

  return debeUsarEtiquetaVideo ? (
    <video {...restProps} />
  ) : (
    <canvas ref={canvasRef} {...restProps} />
  );
}

export default SelfViewContainer;
