import React from "react";
import { makeStyles, Typography, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  overlayContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    fontWeight: 300,
    fontSize: 20,
    marginTop: 40,
    marginLeft: 32,
  },
}));

const Overlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.overlayContainer}>
      <CircularProgress size="200px" />
      <Typography
        className={classes.subtitle}
        variant="overline"
        color="secondary">
        Cargando...
      </Typography>
    </div>
  );
};

export default Overlay;
