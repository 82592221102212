import { combineReducers } from "redux";
import call from "./call";
import error from "./error";

const allReducers = combineReducers({
  call,
  error,
});

const rootReducer = (state, action) => {
  return allReducers(state, action);
};

export default rootReducer;
