import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import ReplayIcon from "@mui/icons-material/Replay";
import React from "react";
import { useSelector } from "react-redux";
import errorImage from "../../../assets/images/errorImage.png";
import { closeIframe } from "../../../utils/iframe";
import ErrorButton from "../errorButton/ErrorButton";

const useStyles = makeStyles((theme) => ({
  errorContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: `linear-gradient(to right, ${theme.palette.primary.errorGradient1}, ${theme.palette.primary.errorGradient2})`,
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    borderBottom: `1px solid ${theme.palette.primary.grey}`,
    "& a": {
      textDecoration: "none",
    },
  },
  errorTitle: {
    margin: 10,
    fontSize: 16,
    fontWeight: 600,
    color: `${theme.palette.secondary.main}`,
    [theme.breakpoints.down(801)]: {
      fontSize: 12,
    },
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderTop: `1px solid ${theme.palette.primary.grey}`,
    borderBottom: `1px solid ${theme.palette.primary.grey}`,
    [theme.breakpoints.down(801)]: {
      border: "none",
    },
  },
  errorIcon: {
    color: `${theme.palette.secondary.main}`,
    [theme.breakpoints.down(801)]: {
      marginTop: 10,
    },
  },
  errorImage: {
    [theme.breakpoints.down(1001)]: {
      width: 620,
    },
    [theme.breakpoints.down(801)]: {
      width: 350,
    },
    [theme.breakpoints.down(701)]: {
      width: 320,
    },
    [theme.breakpoints.down(500)]: {
      width: 420,
    },
  },
  errorButtonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    gap: 30,
  },
}));

const Error = () => {
  const classes = useStyles();
  const call = useSelector((state) => state.call.call)
  const error = useSelector((state) => state.error);
  return (
    <div className={classes.errorContainer}>
      <div className={classes.textContainer}>
        <img className={classes.errorImage} src={errorImage} alt="error"></img>
        <ErrorOutlineIcon sx={{ fontSize: 30 }} className={classes.errorIcon} />
        <Typography className={classes.errorTitle} variant="overline">
          {!error.code
            ? "Algo ha salido mal..."
            : `${error.code} - ${error.message}`}
        </Typography>
      </div>
      <div className={classes.errorButtonsContainer}>
        <ErrorButton
          text="Reintentar"
          icon={<ReplayIcon />}
          action={() => window.location.reload()}
        />
        {
          call.data?.tipoParticipante === 0 &&
          <ErrorButton
            icon={<LogoutIcon />}
            action={closeIframe}
            color="error"
            usarIconoEnBlanco={true}
            text="Volver"
          />
        }
      </div>


    </div>
  );
};

export default Error;
