import { Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { formatDateAndHour } from "../../../utils/datetime";
import Preview from "../call/zoom/components/Preview";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  waitingText: {
    fontSize: 20,
    margin: 5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));

const WaitingRoom = () => {
  const classes = useStyles();

  const {
    apellidoNombrePaciente,
    apellidoNombreProfesional,
    fechaHoraAgendada,
    duracionAgendada,
  } = useSelector((state) => state.call.call.data);

  const esMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item container xs={12}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          margintop={10}
          marginBottom={2}>
          <Typography variant="h4" className={classes.waitingText}>
            Bienvenido/a! {apellidoNombrePaciente.toUpperCase()}. Tu cita está
            programada para el {formatDateAndHour(fechaHoraAgendada).fecha} a
            las {formatDateAndHour(fechaHoraAgendada).hora}. La duración
            aproximada es de {duracionAgendada} minutos. En breve el Dr/a.{" "}
            {apellidoNombreProfesional.toUpperCase()} se conectará...
          </Typography>
        </Grid>
        {!esMobile && (
          <Grid item xs={12}>
            <Preview />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default WaitingRoom;
