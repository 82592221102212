import axios from "axios";
export const SET_PARTICIPANTS = "SET_PARTICIPANTS";
export const POST_ENTER_CALL = "POST_ENTER_CALL";

export const setParticipants = (participants) => {
  return {
    type: SET_PARTICIPANTS,
    payload: participants,
  };
};

export const enterCall = (id) => {
  return (dispatch) => {
    dispatch({
      type: POST_ENTER_CALL,
      payload: axios
        .post(`llamada/v1/ingresar?id=${encodeURIComponent(id)}`)
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response)),
    });
  };
};
