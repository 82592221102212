import CameraAltIcon from "@mui/icons-material/CameraAlt";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import { VideoQuality } from "@zoom/videosdk";
import React, { useCallback, useState } from "react";
import CallButton from "../../../../commons/callButton/CallButton";

const CameraButton = ({ room }) => {
  const [isVideoStarted, setIsVideoStarted] = useState(false);

  const stream = room?.getMediaStream();

  const handleToggleVideoButton = useCallback(async () => {
    // el pedir permiso para usar la camara debe estar ligado
    // a una interaccion de usuario. Es decir, que aprete algun boton
    // o que responda algun dialogo. De lo contrario, algunos navegadores
    // bloquean el pedido
    if (!isVideoStarted) {
      if (stream?.isRenderSelfViewWithVideoElement()) {
        await stream.startVideo({
          videoElement: document.querySelector("#self-view-video"),
        });
      } else {
        const startVideoOptions = {
          hd: true,
          ptz: stream?.isBrowserSupportPTZ(),
        };
        await stream.startVideo(startVideoOptions);
        const canvasElement = document.querySelector(`#self-view-video`);
        stream?.renderVideo(
          canvasElement,
          room.getSessionInfo().userId,
          canvasElement.width,
          canvasElement.height,
          0,
          0,
          VideoQuality.Video_1080P,
        );
      }
      setIsVideoStarted(true);
    } else {
      await stream.stopVideo();
      stream.stopRenderVideo(
        document.querySelector(`#self-view-video`),
        room.getSessionInfo().userId,
      );
      setIsVideoStarted(false);
    }
  }, [room, stream, isVideoStarted]);

  return (
    <CallButton
      src={!isVideoStarted ? <NoPhotographyIcon /> : <CameraAltIcon />}
      action={handleToggleVideoButton}
    />
  );
};

export default CameraButton;
