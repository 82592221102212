import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { enterCall } from "../../redux/actions/call";
import Error from "../commons/errors/Error";
import CallContainer from "./call/CallContainer";

const RoomContainer = () => {
  const dispatch = useDispatch();

  const { code } = useSelector((state) => state.error);
  const [searchParams] = useSearchParams();
  const idRoom = searchParams.get("id");

  useEffect(() => {
    dispatch(enterCall(idRoom));
  }, [idRoom, dispatch]);

  if (code) {
    return <Error />;
  }

  return <CallContainer />;
};

export default RoomContainer;
