import { Button, makeStyles, styled } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  button: {
    borderRadius: 50,
    marginLeft: 10,
    marginRight: 10,
    background: (props) =>
      `${
        !props.disabled
          ? "rgba(255, 255, 255, 0.8)"
          : "rgba(250, 250, 250, 0.2)"
      }`,
    // como ahora los iconos tienen fondo blanco y en llamada el fondo es negro,
    // es necesario especificar un hover:backgroundColor. De no hacerlo, en "hover" el
    // fondo es transparente y desaparece el botón con el fondo en negro.
    "&:hover": {
      backgroundColor: (props) =>
        `${
          !props.disabled
            ? "rgba(255, 255, 255, 0.8)"
            : "rgba(250, 250, 250, 0.2)"
        }`,
    },
    cursor: "pointer",
    "&:nth-last-child(1)": {
      backgroundColor: "rgba(230, 5, 5, 0.652)",
      "&:hover": {
        backgroundColor: "rgba(230, 5, 5, 0.330)",
      },
    },
  },
});

const CallButton = ({ disabled, action, src, usarIconoEnBlanco }) => {
  const classes = useStyles({ disabled });

  const StyledIcon = styled(src.type.type, {
    name: "StyledIcon",
    slot: "Wrapper",
  })({
    // que el icono de salir/terminar llamada sea blanco,
    // ya que contrasta mejor con el rojo
    color: !!usarIconoEnBlanco ? "white" : "black",
  });

  return (
    <Button
      aria-haspopup="true"
      onClick={action}
      disabled={disabled}
      className={classes.button}
      variant="outlined">
      <StyledIcon />
    </Button>
  );
};

export default CallButton;
