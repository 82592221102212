import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import logo from "../../assets/images/marcaAccess.png";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: `linear-gradient(to right, ${theme.palette.primary.homeGradient1}, ${theme.palette.primary.homeGradient2})`,
    height: "100vh",
    width: "100vw",
  },

  image: {
    width: 242,
    height: 60,
    borderBottom: "1px solid rgba(50, 78, 178, 0.332)",
    [theme.breakpoints.down(801)]: {
      width: 170,
      height: 45,
    },
    [theme.breakpoints.down(501)]: {
      width: 140,
      height: 35,
    },
  },
  title: {
    color: "white",
    fontWeight: 500,
    marginBottom: 50,
    [theme.breakpoints.down(801)]: {
      fontSize: 60,
    },
    [theme.breakpoints.down(501)]: {
      fontSize: 45,
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img className={classes.image} src={logo} alt="logo access"></img>
      <Typography variant="h1" className={classes.title}>
        TELEMEDICINA
      </Typography>
    </div>
  );
};

export default Home;
