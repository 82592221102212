const colors = {
  palette: {
    primary: {
      light: "#6ebaa5",
      main: "#2ebf91",
      dark: "#0E615D",
      grey: "#bdbbbb",
      errorGradient1: "#8e9eab",
      errorGradient2: "#eef2f3",
      homeGradient1: "#8360c3",
      homeGradient2: "#2ebf91",
    },
    secondary: {
      light: "#96a8a3",
      main: "#415169",
      dark: "#76afa8",
    },
  },
};
export default colors;
