import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import BackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import notFoundImage from "../../../assets/images/error404Image.png";
import ErrorButton from "../errorButton/ErrorButton";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    background: `linear-gradient(to right, ${theme.palette.primary.errorGradient1}, ${theme.palette.primary.errorGradient2})`,
    "& a": {
      textDecoration: "none",
    },
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.primary.grey}`,
    borderBottom: `1px solid ${theme.palette.primary.grey}`,
    marginLeft: 350,
    marginRight: 350,
    padding: 20,
    [theme.breakpoints.down(801)]: {
      border: "none",
    },
  },
  errorImage: {
    width: 500,
    [theme.breakpoints.down(801)]: {
      width: 400,
    },
  },
  subtitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    fontWeight: 600,
    fontSize: 16,
    color: `${theme.palette.secondary.main}`,
    [theme.breakpoints.down(801)]: {
      fontSize: 12,
    },
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <img
          src={notFoundImage}
          className={classes.errorImage}
          alt="404 error"></img>
        {/* <Typography className={classes.title} variant="h1" color="secondary">
          404
        </Typography> */}
        <Typography className={classes.subtitle} variant="overline">
          Parece que no hay nada por aquí...
        </Typography>
      </div>
      <Link to="/">
        <ErrorButton text="Regresar" icon={<BackIcon />} />
      </Link>
    </div>
  );
};

export default NotFound;
