import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../../redux/actions/error";
import { connectToRoom } from "../../../utils/zoomUtils";
import Overlay from "../../commons/loading/Overlay";
import ZoomParticipantContainer from "./zoom/components/ZoomContainer";
import { RoomContext } from "./zoom/context/roomContext";

const CallContainer = () => {
  const dispatch = useDispatch();

  const llamada = useSelector((state) => state.call.call);

  const [room, setRoom] = useState(null);

  useEffect(() => {
    if (llamada.data.accessToken) {
      connectToRoom(
        llamada.data.accessToken,
        setRoom,
        setErrorMessage,
        dispatch,
      );
    }
  }, [llamada.data.accessToken, dispatch]);

  return (
    <>
      {llamada.loading || !room ? (
        <Overlay />
      ) : (
        <RoomContext.Provider value={room}>
          <ZoomParticipantContainer />
        </RoomContext.Provider>
      )}
    </>
  );
};

export default CallContainer;
