import { useCallback, useEffect, useState } from "react";
import { getVideoLayout } from "../../../../../utils/layout";
import { useRenderVideo } from "./useRenderVideo";

/**
 * Default order of video:
 *  1. video's participants first
 *  2. self on the second position
 */

export function useGalleryLayout(
  zmClient,
  mediaStream,
  isVideoDecodeReady,
  videoRef,
  dimension,
  deberiaEstarEnSalaEspera,
) {
  const [visibleParticipants, setVisibleParticipants] = useState([]);
  const [layout, setLayout] = useState([]);
  const [currentUser, setCurrentUser] = useState();
  const [participants, setParticipants] = useState([]);
  const [subscribedVideos, setSubscribedVideos] = useState([]);

  useEffect(() => {
    setLayout(
      getVideoLayout(
        dimension.width,
        dimension.height,
        visibleParticipants.length,
        deberiaEstarEnSalaEspera,
      ),
    );
  }, [
    dimension.width,
    dimension.height,
    visibleParticipants.length,
    deberiaEstarEnSalaEspera,
  ]);

  const updateUser = useCallback(() => {
    const participants = zmClient.getAllUser();
    setParticipants(participants);
    const currentUser = zmClient.getCurrentUserInfo();
    setCurrentUser(currentUser);
  }, [zmClient]);

  useEffect(() => {
    zmClient.on("user-updated", updateUser);
    zmClient.on("user-added", updateUser);
    zmClient.on("user-removed", updateUser);
    return () => {
      zmClient.off("user-updated", () => {});
      zmClient.off("user-added", () => {});
      zmClient.off("user-removed", () => {});
    };
  }, [updateUser, zmClient]);

  useEffect(() => {
    if (currentUser && participants.length > 0) {
      let pageParticipants = [];

      if (participants.length === 1) {
        pageParticipants = participants;
      } else {
        pageParticipants = participants
          .filter((user) => user.userId !== currentUser.userId)
          .sort(
            (user1, user2) => Number(user2.bVideoOn) - Number(user1.bVideoOn),
          );
        pageParticipants.splice(1, 0, currentUser);
      }

      setVisibleParticipants(pageParticipants);
      const videoParticipants = pageParticipants
        .filter((user) => user.bVideoOn)
        .map((user) => user.userId);
      setSubscribedVideos(videoParticipants);
    }
  }, [zmClient, currentUser, participants]);

  useEffect(() => {
    setParticipants(zmClient.getAllUser());
    setCurrentUser(zmClient.getCurrentUserInfo());
  }, [zmClient]);

  useRenderVideo(
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    layout,
    subscribedVideos,
    visibleParticipants,
    currentUser?.userId,
  );

  return {
    visibleParticipants,
    layout,
  };
}
