import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import React, { useCallback, useState } from "react";
import CallButton from "../../../../commons/callButton/CallButton";

const MicButton = ({ room }) => {
  const [isAudioStarted, setIsAudioStarted] = useState(false);
  const [isMicMuted, setIsMicMuted] = useState(false);

  const stream = room?.getMediaStream();

  const handleToggleMicButton = useCallback(async () => {
    if (isAudioStarted) {
      if (isMicMuted) {
        await stream.unmuteAudio().then((res) => {
          setIsMicMuted(false);
        });
      } else {
        await stream.muteAudio().then((res) => {
          setIsMicMuted(true);
        });
      }
    } else {
      await stream.startAudio().then((res) => {
        setIsAudioStarted(true);
      });
    }
  }, [stream, isAudioStarted, isMicMuted]);

  return (
    <CallButton
      src={isMicMuted || !isAudioStarted ? <MicOffIcon /> : <MicIcon />}
      action={handleToggleMicButton}
    />
  );
};

export default MicButton;
