import { Typography, makeStyles, useTheme } from "@material-ui/core";
import MicOffIcon from "@mui/icons-material/MicOff";
import { useRef } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  avatar: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "visible",
    backgroundColor: (props) =>
      !props.isVideoOn ? "rgba(0, 0, 0, 0.9)" : "transparent",
    border: (props) => (!props.isVideoOn ? "solid 1px grey" : ""),
    // Si no especificamos el zIndex mas alto, lo mas probable
    // es que el participante remoto se renderice por encima del
    // participante local y el video se vea opacado.
    zIndex: (props) => (props.esLocal ? 99 : ""),
  },
  etiquetaInferiorIzquierda: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    color: "white",
    backgroundColor: "rgba(42, 93, 247, 0.75)",
    border: "solid 2px rgba(42, 93, 247, 0.9)",
    borderRadius: "0px 10px 0px 0px",
    fontSize: "18px",
    fontWeight: 500,
    left: 0,
    bottom: 0,
    zIndex: 15,
    padding: 5,
  },
  etiquetaSuperiorIzquierda: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    color: "white",
    backgroundColor: "rgba(42, 93, 247, 0.75)",
    border: "solid 2px rgba(42, 93, 247, 0.9)",
    borderRadius: "0px 0px 10px 0px",
    fontSize: "14px",
    fontWeight: 500,
    left: 0,
    top: 0,
    zIndex: 15,
    padding: 5,
  },
  centerName: {
    zIndex: 16,
    fontWeight: 700,
    color: "#fff",
    textAlign: "center",
  },
});

const Avatar = ({
  participant,
  style,
  tieneViewportPocaAltura,
  esParticipanteLocal,
}) => {
  const theme = useTheme();

  const classes = useStyles({
    isVideoOn: participant.bVideoOn,
    esLocal: esParticipanteLocal,
  });

  const { apellidoNombrePaciente, apellidoNombreProfesional } = useSelector(
    (state) => state.call.call.data,
  );

  const avatarRef = useRef(null);

  const etiquetaNombre =
    participant.displayName === "Paciente"
      ? apellidoNombrePaciente
      : apellidoNombreProfesional;
  const tieneCamaraEncendida = participant.bVideoOn;
  const tieneAudio = participant.audio === "computer";
  // Cuando el usuario todavía no le ha dado permiso al navegador
  // para usar el micrófono, participant.audio es "" (string vacia).
  const audioDesactivado = participant.audio === "";
  const estaMuteado = participant.muted;
  const estaEnFailover = participant.isInFailover;

  return (
    <div className={classes.avatar} style={{ ...style }} ref={avatarRef}>
      {(tieneCamaraEncendida ||
        (tieneAudio && estaMuteado) ||
        audioDesactivado ||
        estaEnFailover) && (
        <div
          className={
            // Cuando el viewport es muy pequeño (height < 401), se coloca
            // la etiqueta en la parte superior izquierda para que no se
            // superponga con los mediaControlButtons.
            tieneViewportPocaAltura
              ? classes.etiquetaSuperiorIzquierda
              : classes.etiquetaInferiorIzquierda
          }>
          {((tieneAudio && estaMuteado) || audioDesactivado) && (
            // que el color del indicador de mute esté en un verde
            // que contrasta bien con el celeste de la etiquetaNombre
            <MicOffIcon style={{ color: theme.palette.primary.main }} />
          )}
          {tieneCamaraEncendida && (
            <span>
              {etiquetaNombre.toUpperCase()} {esParticipanteLocal && " (Usted)"}
            </span>
          )}
        </div>
      )}
      {!tieneCamaraEncendida && (
        <Typography variant="h5" className={classes.centerName}>
          {etiquetaNombre.toUpperCase()}
        </Typography>
      )}
    </div>
  );
};

export default Avatar;
