import { setErrorMessage } from "../actions/error";

const errorMiddleware = (store) => (next) => (action) => {
  if (action.type.includes("persist")) return next(action);

  if (action.type.includes("_REJECTED")) {
    store.dispatch(
      setErrorMessage(action.payload.status, action.payload?.data?.message),
    );
  }

  return next(action);
};

export default errorMiddleware;
